.containerForm {
    display: grid;
    width: 100%;
}

.containerForm h1 {
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: 900;
}

.btnOptionUser {
    max-width: 300px;
    min-height: 160px;
    padding: 20px 15px;
    cursor: pointer;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    /*margin-right: 20px;*/
    border-radius: 20px;
}

.btnOptionUser.selected {
    background-color: var(--theme-color-primary);
    color: white;
}

.btnOptionUser h5 {
    line-height: 25px;
    margin-bottom: 10px;
}

.btnOptionUser .icon {
    margin-right: 20px;
}