.containerForm {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.containerForm h1 {
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: 900;
}

.msg {
    margin-top: 10px;
    color: #3B3C40;
    font-size: 1rem;
}

.listConfirmation {
    padding-left: 10px;
}

.listConfirmation li {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
}

.listConfirmation li b {
    font-weight: 900;
}

.containerBtnConfirmation {
    margin-top: auto;
    padding-bottom: 50px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.btnConfirmation {
    max-width: fit-content;
    padding: 20px 15px;
    border-radius: 8px !important;
}

.svg {
    stroke-dasharray: 600;
    stroke-dashoffset: 600;
    stroke-width: 0.04rem;
    animation: dash 5s ease alternate infinite;
}

@keyframes dash {
    from {
        stroke-dashoffset: 500;
    }
    to {
        stroke-dashoffset: 0;
    }
}

.wrapper {
    margin: 10px;
    position: relative;
}
.buttonProgress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
}