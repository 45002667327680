.containerQR {
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: fit-content;
    margin: 0 auto;
    margin-top: 30px;
}

.containerInfo{
    margin-top: 30px;
}

.listConfirmation {
    margin-top: 10px;
    padding-left: 0px;
}

.listConfirmation li {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
}

.listConfirmation li b {
    font-weight: 900;
}